import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

// Components
import Layout from "components/Layout";
import Main from "components/Layout/Main";
import ArticleSidebar from "components/Sections/Article/ArticleSidebar";
import ArticleHeader from "components/Sections/Article/ArticleHeader";
import ArticleFooter from "components/Sections/Article/ArticleFooter";
// Model
import { IArticle } from "models/Article";
import { IUser } from "models/User";
import { IOptionalMetaProps } from "models/Seo";

type DataProps = {
  article: IArticle;
  writer: IUser;
  translations: {
    count: number;
  };
  articles: {
    count: number;
  };
};

const BlogPostTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
  const {
    title,
    date,
    description,
    thumbnail,
    tags,
  } = data.article.frontmatter;
  const { timeToRead, body } = data.article;
  const { translations, articles } = data;
  const writer = data.writer;

  const seo: IOptionalMetaProps = {
    title,
    description:
      description ||
      `Artículo de ${writer.name}${
        data && `, publicado el ${data}.`
      }. - ${title}`,
    author: {
      name: writer.name,
      twitter: writer.twitter,
    },
    image: thumbnail?.url,
  };

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <ArticleHeader
            title={title}
            thumbnail={thumbnail?.childImageSharp.gatsbyImageData}
            date={date}
            timeToRead={timeToRead}
          />
          <hr />
          <MDXRenderer>{body}</MDXRenderer>
          <hr />
          <ArticleFooter slug={data.article.fields.slug} message={title} />
          <hr />
        </article>
      </Main>
      <ArticleSidebar
        translationsCount={translations.count}
        articlesCount={articles.count}
        writer={writer}
        tags={tags}
      />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query ArticlePageQuery($slug: String!, $writer: String!) {
    article: mdx(
      fields: { slug: { eq: $slug }, collection: { eq: "articles" } }
    ) {
      id
      excerpt(pruneLength: 160)
      body
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD [de] MMMM, YYYY", locale: "es")
        description
        thumbnail {
          url: publicURL
          childImageSharp {
            gatsbyImageData(
              width: 920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        tags
      }
    }

    writer: user(slug: { eq: $writer }) {
      slug
      name
      description
      twitter
      facebook
      instagram
      telegram
      youtube
      spotify
      avatar {
        childImageSharp {
          gatsbyImageData(
            width: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }

    translations: allMdx(
      filter: {
        frontmatter: { translator: { eq: $writer } }
        fields: { collection: { eq: "songs" } }
      }
    ) {
      count: totalCount
    }

    articles: allMdx(
      filter: {
        frontmatter: { writer: { eq: $writer }, tags: { ne: "WIP" } }
        fields: { collection: { eq: "articles" } }
      }
    ) {
      count: totalCount
    }
  }
`;
