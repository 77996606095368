import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const KoFi: React.FC = () => {
  return (
    <>
      <h2 className="title">
        <FontAwesomeIcon icon={["fas", "coffee"]} /> Ko-fi
      </h2>
      <section style={{ textAlign: "center" }} className={"sidebar_card"}>
        <p>
          A través de nuestra página en Ko-Fi es posible apoyarnos económicamente para ayudar a solventar los gastos de
          la página web y así poder mantenerla en línea.
        </p>
        <p>
          <strong>¡El aporte más básico puede hacer la diferencia!</strong>
        </p>
        <a className={"sidebar_button"} href="https://ko-fi.com/tsuini" rel="noopener noreferrer" target="_blank">
          Ir a nuestro Ko-Fi
        </a>
      </section>
    </>
  )
}

export default KoFi
