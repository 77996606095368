import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import Sidebar from "components/Layout/Sidebar";
import KoFi from "../Sidebar/KoFi";
import Follow from "components/UIElements/Follow";
// Models
import { IUser } from "models/User";

type ArticleSidebarProps = {
  writer: IUser;
  translationsCount: number;
  articlesCount: number;
  tags?: string[];
  kohi?: boolean;
  follow?: boolean;
};

const ArticleSidebar: React.FC<ArticleSidebarProps> = ({
  writer,
  translationsCount,
  articlesCount,
  tags,
  kohi = true,
  follow = true,
}) => {
  const writerAvatar = getImage(writer.avatar.childImageSharp.gatsbyImageData);
  return (
    <Sidebar>
      <h2 className="title">Publicado por</h2>
      <section className={"sidebar_featured"}>
        {writerAvatar && (
          <Link to={`/users/${writer.slug}/articles`}>
            <GatsbyImage
              loading="eager"
              className={"sidebar_featured__avatar"}
              image={writerAvatar}
              alt={writer.name}
            />
          </Link>
        )}
        <div className={"sidebar_featured__info"}>
          <h3 className={"sidebar_featured__name"}>
            <Link to={`/users/${writer.slug}/articles`}>{writer.name}</Link>
          </h3>
          <div className={"sidebar_featured__stats"}>
            {!!translationsCount && (
              <Link to={`/users/${writer.slug}/translations`}>
                Traducciones: {translationsCount}
              </Link>
            )}
            {!!articlesCount && (
              <Link to={`/users/${writer.slug}/articles`}>
                Articulos: {articlesCount}
              </Link>
            )}
          </div>
        </div>
        <div className={"sidebar_featured__social"}>
          {writer.youtube && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`${writer.youtube}`}
              aria-label="Youtube"
            >
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            </a>
          )}
          {writer.spotify && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`${writer.spotify}`}
              aria-label="Spotify"
            >
              <FontAwesomeIcon icon={["fab", "spotify"]} />
            </a>
          )}
          {writer.twitter && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://twitter.com/${writer.twitter}`}
              aria-label="Twitter"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
          )}
          {writer.instagram && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://instagram.com/${writer.instagram}`}
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          )}
          {writer.facebook && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://fb.me/${writer.facebook}`}
              aria-label="Facebook"
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
          )}
          {writer.telegram && (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={"sidebar_featured__social_link"}
              href={`https://t.me/${writer.telegram}`}
              aria-label="Telegram"
            >
              <FontAwesomeIcon icon={["fab", "telegram"]} />
            </a>
          )}
        </div>
      </section>

      {tags && tags.length > 0 && (
        <>
          <h2 className="title">Categorías</h2>
          <section className={"sidebar_list"}>
            {tags.map((tag) => {
              const name = tag.charAt(0).toUpperCase() + tag.slice(1);
              return (
                <Link
                  key={tag}
                  className={"sidebar_list__element"}
                  to={`/tags/${tag}`}
                >
                  <h3 className={"sidebar_list__element_title"}>{name}</h3>
                </Link>
              );
            })}
          </section>
        </>
      )}
      {kohi && <KoFi />}
      <hr />
      {follow && (
        <section className={"sidebar_card_alt"}>
          <h2 className="title">Síguenos</h2>
          <Follow />
        </section>
      )}
    </Sidebar>
  );
};

export default ArticleSidebar;
