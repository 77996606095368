import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, useStaticQuery } from "gatsby"

// Model
import { ISite } from "models/Seo"
// Styles
import * as styles from "./Share.module.css"

type ShareProps = {
  pathname: string
  message?: string
}

const Share: React.FC<ShareProps> = ({ pathname, message }) => {
  const { site }: ISite = useStaticQuery(query)
  const url = site.siteMetadata.siteUrl + pathname

  return (
    <>
      <nav className={styles.share}>
        <a
          href={`https://twitter.com/intent/tweet?url=${url}&via=${site.siteMetadata.twitter}${
            message ? `&text=${message}` : ""
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.share_social} data-social="twitter">
            <FontAwesomeIcon icon={["fab", "twitter"]} />
            <span>Twitter</span>
          </div>
        </a>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noopener noreferrer">
          <div className={styles.share_social} data-social="facebook">
            <FontAwesomeIcon icon={["fab", "facebook"]} />
            <span>Facebook</span>
          </div>
        </a>
        <a
          href={`https://t.me/share/url?url=${message ? `${message} ` : ""}${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.share_social} data-social="telegram">
            <FontAwesomeIcon icon={["fab", "telegram"]} />
            <span>Telegram</span>
          </div>
        </a>
        <a href={`https://web.whatsapp.com/send?phone&text=${url}`} target="_blank" rel="noopener noreferrer">
          <div className={styles.share_social} data-social="whatsapp">
            <FontAwesomeIcon icon={["fab", "whatsapp"]} />
            <span>Whatsapp</span>
          </div>
        </a>
      </nav>
    </>
  )
}

export default Share

const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
        twitter
      }
    }
  }
`
