import * as React from "react"

// Components
import Share from "components/UIElements/Share"

type ArticleFooterProps = {
  slug: string
  message?: string
}

const ArticleFooter: React.FC<ArticleFooterProps> = ({ slug, message }) => {
  return (
    <>
      <h2 className="title">Compartir</h2>
      <Share pathname={`/articles${slug}`} message={message} />
    </>
  )
}

export default ArticleFooter
