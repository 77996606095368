import * as React from "react"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import * as styles from "./ArticleHeader.module.css"

type ArticleHeaderProps = {
  title: string
  thumbnail?: IGatsbyImageData
  date: string
  timeToRead: number
}

const ArticleHeader: React.FC<ArticleHeaderProps> = ({ title, thumbnail, date, timeToRead }) => {
  const image = thumbnail ? getImage(thumbnail) : undefined
  return (
    <>
      <h2 className="title">{title}</h2>
      <header className={styles.article_header}>
        {image && <GatsbyImage className={styles.article_thumbnail} image={image} alt={title} />}
        <small className={styles.article_info}>
          <span>
            <FontAwesomeIcon icon={["far", "calendar-alt"]} /> <span className={styles.article_date}>{date}</span>
          </span>
          <span>
            <FontAwesomeIcon icon={["far", "clock"]} /> {timeToRead} {timeToRead > 1 ? "Minutos" : "Minuto"} de lectura
          </span>
        </small>
      </header>
    </>
  )
}

export default ArticleHeader
